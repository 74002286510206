/* React */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/* UI Components */
import Navbar from "../../Common/Navbar";

/* Styling */
import "./VerifyPhoneVerificationCode.css";
import { postWithAuth } from "../../../firebase/authentication/auth";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

enum TwilioVerificationStatus {
  PENDING = "pending",
  APPROVED = "approved",
  CANCELED = "canceled",
  MAX_ATTEMPTS_REACHED = "max_attempts_reached",
  DELETED = "deleted",
  FAILED = "failed",
  EXPIRED = "expired",
}

const VerifyPhone: React.FC = () => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [message, setMessage] = useState<string>("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phone = queryParams.get("number");
  const navigate = useNavigate();

  useEffect(() => {
    if (!phone) {
      navigate("/404");
    }
  }, [phone, navigate]);

  const handleChange = (index: number, value: string) => {
    if (code.some((digit) => digit === "")) {
      setMessage("");
    }
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value.replace(/\D/g, "");
      setCode(newCode);

      if (value && index < 5) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const disableSubmission = code.some((digit) => digit === "");

  // TODO: Create a function to reuse the send for more usability code.
  const resendCode = async () => {
    if (!phone) {
      return;
    }

    try {
      const response = await postWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone/send-verification-code`
      );

      if (response.ok) {
        navigate(`/verify-phone?number=${encodeURIComponent(phone)}`);
        setMessage("Message sucessfully resent.");
      } else {
        console.error("Failed to send verification code:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending verification code:", error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("code", code.join(""));

    try {
      const response = await postWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone/verify-code`,
        formData
      );

      if (response.ok) {
        const data = await response.json();
        const verificationStatus =
          data.verification as TwilioVerificationStatus;

        switch (verificationStatus) {
          case TwilioVerificationStatus.APPROVED:
            navigate(`/disclosure`);
            break;
          case TwilioVerificationStatus.PENDING:
            setMessage("Your phone verification is still pending.");
            break;
          case TwilioVerificationStatus.CANCELED:
            setMessage(
              "Verification has been canceled. Please resend the code."
            );
            break;
          case TwilioVerificationStatus.MAX_ATTEMPTS_REACHED:
            setMessage(
              "Maximum verification attempts reached. Please request a new code."
            );
            break;
          case TwilioVerificationStatus.DELETED:
            setMessage(
              "Verification was deleted. Please initiate the verification again."
            );
            break;
          case TwilioVerificationStatus.FAILED:
            setMessage("Verification failed. Please try again.");
            break;
          case TwilioVerificationStatus.EXPIRED:
            setMessage("Verification code expired. Please request a new code.");
            break;
          default:
            setMessage("An unknown verification status was returned.");
        }
      } else {
        switch (response.status) {
          case 401:
            setMessage("User is invalid or expired. Please send again");
            break;
          case 404:
            setMessage("User doesn't exist.");
            break;
          case 409:
            setMessage("Number already verified with an account.");
            break;
          case 500:
            setMessage("Server error. Please try again later.");
            break;
          default:
            setMessage("An unexpected error occurred. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error sending verification code:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="verify-phone-verification-code">
        <div className="verify-phone-verification-code__title">
          Phone Verification
        </div>

        <form className="verify-phone-verification-code__form">
          <div className="verify-phone-verification-code__instruction">
            Enter your six-digit code here
          </div>
          <div className="verify-phone-verification-code__code-sent">
            A verification code has been sent to:
            <div className="verify-phone-verification-code__phone-number">
              {phone}
            </div>
          </div>

          <div className="verify-phone-verification-code__code-inputs">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`input-${index}`}
                type="text"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                maxLength={1}
                className="verify-phone-verification-code__code-input"
              />
            ))}
          </div>

          <div className="verify-phone-verification-code___message">
            {message && (
              <p
                style={{
                  color:
                    message === "Message sucessfully resent."
                      ? "white"
                      : "#ff5757",
                }}
              >
                {message}
              </p>
            )}
          </div>

          <button
            onClick={handleSubmit}
            className={`verify-phone-verification-code__button verify-phone-verification-code__button--primary ${
              disableSubmission
                ? "verify-phone-verification-code__button--disabled"
                : ""
            }`}
            disabled={disableSubmission}
          >
            Verify
          </button>

          <p className="verify-phone-verification-code__resend">
            Didn't receive code?{" "}
            <span
              className="verify-phone-verification-code__resend-link"
              onClick={resendCode}
            >
              Send it again
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default VerifyPhone;
