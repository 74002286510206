/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* UI Components */
import Navbar from "../../Common/Navbar";
import DropZone from "./DropZone";

/* Functions and Utils */
import { startAnalysis } from "./api";
import {
  withRequirements,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  userSubscribedOrTrialedRequirement,
} from "../../Requirements";

/* Styling */
import "./DisclosureUpload.css";

function DisclosureUpload() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const navigate = useNavigate();

  /*
  =============================================================
  STATE
  =============================================================
  */

  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (selectedFile) {
      setUploadingFile(true); // Show spinner
      startAnalysis(selectedFile, (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          setUploadProgress(percentCompleted);
        }
      })
        .then((disclosureId) => {
          navigate(`/disclosure/analysis?disclosure=${disclosureId}`);
        })
        .catch((error) => {
          console.error("Error starting analysis:", error);
          setSelectedFile(null);
          setUploadingFile(false); // Hide spinner and show DropZone again
          setUploadProgress(0); // Reset progress
        });
    }
  }, [selectedFile, navigate]);

  /*

  =============================================================
  RENDERING
  =============================================================
  */
  return (
    <div>
      <Navbar />
      <div className="disclosure-upload-page-title">Disclosure Analysis</div>
      <div className="disclosure-upload-page-subtitle">
        Generate a disclosure analysis in minutes
      </div>
      {uploadingFile ? (
        <div className="spinner-box">
          <div className="spinner-container">
            <div className="large-spinner"></div>
            {uploadProgress > 0 && (
              <div className="progress-text">{uploadProgress}%</div>
            )}
          </div>
        </div>
      ) : (
        <DropZone
          uploadingFile={uploadingFile}
          setUploadingFile={setUploadingFile}
          setSelectedFile={setSelectedFile}
        />
      )}
    </div>
  );
}

export default withRequirements(DisclosureUpload, [
  userSignedInRequirement,
  userPhoneVerifiedRequirement,
  userSubscribedOrTrialedRequirement,
]);
