/* React */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/* UI Components */
import ErrorCircle from "../../../assets/error_circle.svg";
import Spinner from "../../Common/Spinner/FormSpinner";

/* Functionality */
import { logInCustomToken } from "../../../firebase/authentication/functions";

/* Styling */
import "./LogIn.css";
import Navbar from "../../Common/Navbar";
import { notSignedInRequirement, withRequirements } from "../../Requirements";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function Login() {
  /* 
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  /* 
  ================================
  UI Functionality
  ================================
  */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessage("");
  };

  /* 
  ================================
  SUBMISSION
  ================================
  */
  const disableSubmission =
    formData.username === "" || formData.password === "";
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    const stringResponse = await fetchLoginToken(
      formData.username,
      formData.password
    );

    if (stringResponse === "Success") {
      setIsLoading(false);
      navigate(`/dashboard`);
    }
    setErrorMessage(stringResponse);
    setIsLoading(false);
  };

  /* 
  ================================
  RENDERING
  ================================
  */
  return (
    <div>
      <Navbar />
      <div className="login">
        <div className="login__title">Log In</div>

        <form className="login__form">
          <div className="login__inputs">
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className="login__input"
              placeholder="Username"
            />

            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="login__input"
              placeholder="Password"
            />
          </div>

          <div className="signup__error-message">
            {errorMessage && (
              <p className="login__password-alert">
                <img
                  src={ErrorCircle}
                  alt="Error Icon"
                  className="login__error-icon"
                />
                {errorMessage}
              </p>
            )}
          </div>

          <div className="login__controls">
            {isLoading ? (
              <Spinner />
            ) : (
              <button
                onClick={handleSubmit}
                className={`login__button login__button--primary ${
                  disableSubmission ? "login__button--disabled" : ""
                }`}
                disabled={disableSubmission}
              >
                Continue
              </button>
            )}
          </div>
        </form>

        <div className="login__links">
          <p className="login__signup-link">
            Don't have an account <a href="/signup">Sign Up</a>
          </p>
          <p className="login__password-reset-link">
            Forgot your password? <a href="/">Reset Password</a>{" "}
            {/* TODO: Update link*/}
          </p>
        </div>
      </div>
    </div>
  );
}

async function fetchLoginToken(
  username: string,
  password: string
): Promise<string> {
  const formBody = new FormData();
  formBody.append("username", username);
  formBody.append("password", password);

  try {
    const tokenResponse = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/request-login-token`,
      {
        method: "POST",
        body: formBody,
      }
    );

    if (tokenResponse.ok) {
      const tokenData = await tokenResponse.json();
      const customToken = tokenData.token;

      // Use the custom token to sign in
      await logInCustomToken(customToken);

      return "Success";
    } else {
      switch (tokenResponse.status) {
        case 400:
          return "Invalid username or password";
        case 500:
          return "Server error. Please try again later.";
        default:
          return "An unexpected error occurred. Please try again.";
      }
    }
  } catch (error) {
    console.error("Error during token retrieval:", error);
    return "Network error. Please check your connection.";
  }
}

export { fetchLoginToken };
export default withRequirements(Login, [notSignedInRequirement]);
