/* Data Models */
import {
  ComponentAnalysisModule,
  ComponentAnalysisStatus,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import { ComponentTypeDisplayMapping } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import { SelectedPage } from "../../PDFViewer/PDFViewer";

/* UI Components */
import { ComponentAnalysisTitle, CorrespondingFiles } from "../Common/";
import { ComponentViewerSelector } from "./ComponentViewerSelector";

/* Functions and Utils */
import PDFCache from "../../../../../../utils/cache/pdf";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../../../../../firebase/authentication/auth";
import { DisclosurePackage } from "../../../../../../model/DisclosureAnalysis/DisclosurePackage";
import { Reference } from "../../../../../../model/DisclosureAnalysis/Reference/Reference";
import ChatView from "../Chat/ChatView";
import {
  ChatMessage,
  ChatRole,
  FirestoreChatRecord,
} from "../../../../../../model/DisclosureAnalysis/Chat/Chat";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/**
 * PROPS: for the ComponentModuleViewer component.
 */
export interface ComponentModuleViewerProps {
  disclosurePackage: DisclosurePackage;
  componentAnalysisModule: ComponentAnalysisModule;
  pdfCache: PDFCache | null;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}

/**
 * COMPONENT: displays the analysis of a component based on its status.
 */
export default function ComponentModuleViewer({
  disclosurePackage,
  componentAnalysisModule,
  pdfCache,
  setSelectedPage,
}: ComponentModuleViewerProps): JSX.Element {
  const [chatRecord, setChatRecord] = useState<FirestoreChatRecord>({
    messages: [],
  });

  const handleSendMessage = async (message: string) => {
    //add user messages to chat
    const userMessage: ChatMessage = {
      role: ChatRole.USER,
      content: message,
      reference: null,
    };
    setChatRecord((prev) => ({ messages: [...prev.messages, userMessage] }));

    const currentUser = await getCurrentUser();

    if (
      !currentUser?.uid ||
      !componentAnalysisModule.id ||
      !disclosurePackage.id
    ) {
      console.error("Missing required data");
      return;
    }

    const payload = {
      question: message,
      pdf_references:
        componentAnalysisModule.associated_files.map((file) => ({
          uid: currentUser.uid,
          disclosure_id: disclosurePackage.id,
          file_id: file.pdf.file_id,
        })) || [],
      component_id: componentAnalysisModule.id,
      disclosure_id: disclosurePackage.id,
      user_id: currentUser.uid,
    };

    try {
      const response = await fetch(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/chat/message`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const aiMessage: ChatMessage = await response.json();
      setChatRecord((prev) => ({ messages: [...prev.messages, aiMessage] }));
    } catch (error) {
      // Handle error (e.g., show error message to user)
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    if (componentAnalysisModule) {
      setChatRecord(componentAnalysisModule.associated_chat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentAnalysisModule]);

  const onReferenceClick = (reference: Reference) => {
    if (reference.page != null && reference.pdf) {
      setSelectedPage({ page: reference.page, fileID: reference.pdf?.file_id });
    }
  };

  // Display component analysis based on stats
  switch (componentAnalysisModule.status) {
    case ComponentAnalysisStatus.UNINITIATED:
      return <div>Uninitiated.</div>;
    case ComponentAnalysisStatus.FILECLASSIFYING:
      return (
        <div className="analysis-module-fileclassifying">Detecting Files</div>
      );
    case ComponentAnalysisStatus.ANALYZING:
      return <div className="analysis-module-analyzing">Analyzing</div>;
    case ComponentAnalysisStatus.FILES_NOT_DETECTED:
      return (
        <div className="analysis-module">
          <div className="component-wrapper">
            <ComponentAnalysisTitle type={componentAnalysisModule.type} />
            <span style={{ fontWeight: "275" }}>No files relating to </span>
            {ComponentTypeDisplayMapping[componentAnalysisModule.type]} in this
            package.
          </div>
        </div>
      );
    case ComponentAnalysisStatus.COMPLETE:
      return (
        <div className="analysis-module">
          <div className="component-wrapper">
            <ComponentAnalysisTitle type={componentAnalysisModule.type} />
            <CorrespondingFiles
              files={componentAnalysisModule.associated_files}
              pdfCache={pdfCache}
            />
            <ComponentViewerSelector
              component={componentAnalysisModule}
              setSelectedPage={setSelectedPage}
            />
          </div>

          <ChatView
            chatRecord={chatRecord}
            onSendMessage={handleSendMessage}
            onReferenceClick={onReferenceClick}
            componentType={componentAnalysisModule.type}
          />
        </div>
      );
    case ComponentAnalysisStatus.ERROR:
      return (
        <div className="analysis-module">
          <div className="component-wrapper">
            <ComponentAnalysisTitle type={componentAnalysisModule.type} />
            Error performing analysis.
          </div>
        </div>
      );
    default:
      return <div>This should not show</div>;
  }
}
