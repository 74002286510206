import React from "react";
import "./FormSpinner.css"

const FormSpinner: React.FC = () => {
  return (
    <div className="form-spinner">
      <div className="form-spinner__circle">
        <div className="form-spinner__circle-gradient" />

        <div className="form-spinner__circle-inner" />
      </div>
    </div>
  );
};

export default FormSpinner;
