/* React */
import React, { useState } from "react";

/* Data Models */
import {
  AnalysisAttributeCategory,
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";
import {
  SellerQuestionnaireAnalysis,
  SellerQuestionnaireAnalysisAttribute,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/SellerQuestionnaire/SellerQuestionnaireAnalysis";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

/* Styling */
import arrowIcon from "../../../assets/SectionArrow.svg";
import "../Components.css";
import "./SellerQuestionnaire.css";

export default function SellerQuestionnaireAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: SellerQuestionnaireAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({
    "Red Flags": true,
    "Pertinent Info": false,
    Other: false,
  });

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  const attributes = Object.entries(analysis.items)
    .filter(([key]) => key.valueOf() !== "analysis_sub_type")
    .map(([, value]) => value);

  const categorizedItems = attributes.reduce<{
    redFlag: SellerQuestionnaireAnalysisAttribute[];
    pertinent: SellerQuestionnaireAnalysisAttribute[];
    other: SellerQuestionnaireAnalysisAttribute[];
  }>(
    (acc, item) => {
      switch (item.category) {
        case AnalysisAttributeCategory.REDFLAG:
          acc.redFlag.push(item);
          break;
        case AnalysisAttributeCategory.INFO:
          acc.pertinent.push(item);
          break;
        case AnalysisAttributeCategory.DUMP:
          acc.other.push(item);
          break;
      }
      return acc;
    },
    { redFlag: [], pertinent: [], other: [] }
  );

  const renderItems = (items: SellerQuestionnaireAnalysisAttribute[]) =>
    items.length > 0 && (
      <div className="component-analysis-section-list">
        {items
          .sort(
            (a, b) =>
              SeverityLevelOrder[a.severity || "MINOR"] -
              SeverityLevelOrder[b.severity || "MINOR"]
          )
          .map((item, index) => (
            <div
              key={index}
              className={`component-analysis-section-item ${
                item.reference ? "has-reference" : ""
              }`}
              onClick={() => {
                if (item.reference) {
                  setSelectedPage({
                    fileID: item.reference.pdf?.file_id,
                    page: item.reference.page,
                    region: item.reference.region,
                  } as SelectedPage);
                }
              }}
            >
              <div className="component-analysis-section-item-description bold">
                {item.description}
              </div>
              {item.severity !== SeverityLevel.NOTAPPLICABLE &&
                item.severity !== SeverityLevel.NONE && (
                  <div className={`severity-box ${item.severity}`}>
                    {item.severity}
                  </div>
                )}
            </div>
          ))}
      </div>
    );

  const renderSection = (
    title: string,
    items: SellerQuestionnaireAnalysisAttribute[]
  ) =>
    items.length > 0 && (
      <div className="component-analysis-section">
        <div
          className="component-analysis-section-header"
          onClick={() => toggleSection(title)}
        >
          <div>
            <img
              src={arrowIcon}
              alt="toggle"
              className={`component-analysis-section-header-arrow ${
                expandedSections[title] ? "expanded" : "collapsed"
              }`}
            />
            {title}
          </div>
          <div className="component-analysis-section-header-brief">
            {items.length}
          </div>
        </div>
        {expandedSections[title] && renderItems(items)}
      </div>
    );

  return (
    <div>
      {renderSection("Red Flags", categorizedItems.redFlag)}
      {renderSection("Pertinent Info", categorizedItems.pertinent)}
      {renderSection("Other", categorizedItems.other)}
    </div>
  );
}
