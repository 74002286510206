/* React */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Data Models */
import { UserPhone } from "../../../model/DisclosureAnalysis/UserPhone";

/* UI Components */
import Navbar from "../../Common/Navbar";

/* Functionality */
import {
  getWithAuth,
  postWithAuth,
} from "../../../firebase/authentication/auth";
import {
  withRequirements,
  userSignedInRequirement,
  userNotAlreadyPhoneVerified,
} from "../../Requirements";

/* Styling */
import "./SendPhoneVerificationCode.css";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function SendPhoneVerificationCode() {
  const [phoneData, setPhoneData] = useState<UserPhone | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPhoneVerification = async () => {
      try {
        const response = await getWithAuth(
          `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone`
        );
        const data = await response.json();

        if (!data.verified) {
          setPhoneData(data as UserPhone);
        }
      } catch (error) {
        console.error("Error getting phone number:", error);
      }
    };

    checkPhoneVerification();
  }, []);

  const sendPhoneCode = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!phoneData) {
      return;
    }

    try {
      const response = await postWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone/send-verification-code`
      );

      if (response.ok) {
        navigate(
          `/verify-phone?number=${encodeURIComponent(phoneData.number)}`
        );
      } else {
        console.error("Failed to send verification code:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending verification code:", error);
    }
  };

  return (
    <div>
      <Navbar />
      {phoneData ? (
        <div className="send-phone-verification-code">
          <form className="send-phone-verification-code__form">
            <h2 className="send-phone-verification-code__title">
              Access to certain content requires phone verification
            </h2>

            <div className="send-phone-verification-code__destination">
              A verification code will be sent to:
              <div className="send-phone-verification-code__phone-number">
                {phoneData.number}
              </div>
            </div>

            <button
              onClick={sendPhoneCode}
              className="send-phone-verification-code__button send-phone-verification-code__button--primary"
            >
              Send code
            </button>
            <br />
          </form>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default withRequirements(SendPhoneVerificationCode, [
  userSignedInRequirement,
  userNotAlreadyPhoneVerified,
]);
