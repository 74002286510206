/* React */
import React, { useEffect, useState, useCallback } from "react";

/* UI Components */
import ShareDisclosureAnalysisButton from "./ShareDisclosureAnalysisButton";
import PackageInfo from "./PackageInfo";
import PDFViewer, { SelectedPage } from "./PDFViewer/PDFViewer";
import AnalysesNavViewer from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationAnalyses";
import DisclosureNavigationToggle from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationToggle";
import DisclosureNavigationFiles from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationFiles";
import DisclosureAnalysisShimmy from "./DisclosureViewerShimmy";
import { DisclosureNavigationSection } from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationSection";
import AnalysisModuleViewer from "./AnalysisModuleViewer/AnalysisModuleViewer";

/* Data Models */
import { DisclosurePackage } from "../../../../model/DisclosureAnalysis/DisclosurePackage";
import { PDFFile } from "../../../../model/DisclosureAnalysis/PDFFile";
import { ClassifiedFile } from "../../../../model/DisclosureAnalysis/ClassifiedFile";
import {
  AnalysisModule,
  AnalysisModuleType,
} from "../../../../model/DisclosureAnalysis/AnalysisModules/AnalysisModule";
import { ComponentAnalysisModule } from "../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";

/* Functions and Utils */
import { fetchPdfFile } from "./../api/";
import PDFCache from "../../../../utils/cache/pdf";

/* Styling */
import "./DisclosureViewer.css";
import { CustomComponentAnalysisModule } from "../../../../model/DisclosureAnalysis/AnalysisModules/CustomComponentAnalysisModule";
import ComponentAnalysisType, {
  ComponentTypeDisplayMapping,
} from "../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* COMPONENT PROPS */
interface DisclosureAnalysisViewerProps {
  disclosurePackage: DisclosurePackage | null;
  pdfFiles: PDFFile[];
  pdfCache: PDFCache;
  disclosureId: string | null;
  uid: string | null;
  link: string | null;
}

/* COMPONENT */
export function DisclosureAnalysisViewer({
  disclosurePackage,
  pdfFiles,
  pdfCache,
  disclosureId,
  uid,
  link,
}: DisclosureAnalysisViewerProps): JSX.Element {
  /**
   * State Variables
   */
  const [currentAnalysisModule, setCurrentAnalysisModule] =
    useState<AnalysisModule | null>(null);
  const [selectedNav, setSelectedNav] = useState<DisclosureNavigationSection>(
    DisclosureNavigationSection.ANALYSIS
  );
  const [pdfFilesToRender, setPdfFilesToRender] = useState<PDFFile[]>([]);
  const [selectedPage, setSelectedPage] = useState<SelectedPage | null>(null);
  const [isNavExpanded, setIsNavExpanded] = useState(true); // Default to true for desktop
  const [isDarkMode, setIsDarkMode] = useState(true); // Dark mode state

  /**
   * Utility Functions
   */
  const convertClassifiedFiletoPDFFile = useCallback(
    async (classifiedFiles?: ClassifiedFile[]): Promise<PDFFile[]> => {
      if (
        classifiedFiles &&
        classifiedFiles.length > 0 &&
        currentAnalysisModule &&
        pdfCache
      ) {
        const pdfs = await Promise.all(
          classifiedFiles.map(async (file) => {
            const cachedPDF = await pdfCache.get(file.pdf.file_id);
            if (cachedPDF) {
              return cachedPDF;
            } else {
              const fetchedPDF = await fetchPdfFile(file.pdf);
              await pdfCache.store(fetchedPDF, file.pdf.file_id);
              return fetchedPDF;
            }
          })
        );
        return pdfs;
      }
      return [];
    },
    [currentAnalysisModule, pdfCache]
  );

  /**
   * Hooks
   */
  useEffect(() => {
    const updatePDFsToRender = async () => {
      if (currentAnalysisModule === null) {
        setPdfFilesToRender([]);
        return;
      }

      // Base Component
      if (
        currentAnalysisModule.analysis_module_type ===
        AnalysisModuleType.COMPONENT
      ) {
        const componentModule =
          currentAnalysisModule as ComponentAnalysisModule;

        const pdfs = await convertClassifiedFiletoPDFFile(
          componentModule?.associated_files
        );
        setPdfFilesToRender(pdfs);
      }

      // Custom Component
      if (
        currentAnalysisModule.analysis_module_type ===
        AnalysisModuleType.CUSTOM_COMPONENT
      ) {
        const componentModule =
          currentAnalysisModule as CustomComponentAnalysisModule;

        const pdfs = await convertClassifiedFiletoPDFFile(
          componentModule?.associated_files
        );
        setPdfFilesToRender(pdfs);
      }

      // Master
      if (
        currentAnalysisModule.analysis_module_type ===
        AnalysisModuleType.MASTER_REPORT
      ) {
        setPdfFilesToRender(pdfFiles);
      }
    };

    updatePDFsToRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnalysisModule, pdfFiles, disclosurePackage]);

  /**
   * Synchronize dark mode class with isDarkMode state
   */
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    // Clean up on unmount
    return () => {
      document.body.classList.remove("dark-mode");
    };
  }, [isDarkMode]);

  useEffect(() => {
    setPdfFilesToRender(pdfFiles);
    if (disclosurePackage) {
      setCurrentAnalysisModule(disclosurePackage.analysis.master);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfFiles]);

  const toggleNav = () => {
    setIsNavExpanded((prevState) => !prevState);
  };

  const getCurrentModuleName = () => {
    if (
      currentAnalysisModule &&
      currentAnalysisModule.analysis_module_type ===
        AnalysisModuleType.COMPONENT
    ) {
      const componentModule = currentAnalysisModule as ComponentAnalysisModule;
      return componentModule.type;
    }

    return selectedNav === DisclosureNavigationSection.ANALYSIS
      ? "MASTERREPORT"
      : "Files";
  };

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  /**
   * Rendering
   */
  return (
    <div className="disclosure-viewer">
      {disclosurePackage ? (
        <>
          <div className="disclosure-viewer-header">
            <PackageInfo propertyName={disclosurePackage.property_name} />
            {/* Dark Mode Toggle Button */}
            <label className="dark-mode-toggle">
              <input
                type="checkbox"
                checked={isDarkMode}
                onChange={toggleDarkMode}
                aria-label="Toggle Dark Mode"
              />
              <span className="slider"></span>
            </label>
            <ShareDisclosureAnalysisButton
              uid={uid}
              disclosure_id={disclosureId}
              share_link={link ? window.location.href : null}
            />
          </div>
          <div className="disclosure-viewer-viewers">
            <div className="disclosure-viewer-viewers-analysis">
              <div
                className={`disclosure-viewer-navigation ${
                  isNavExpanded ? "expanded" : "collapsed"
                }`}
                onClick={toggleNav}
              >
                <div
                  className={`disclosure-viewer-selected-section ${getCurrentModuleName()}`}
                >
                  {getCurrentModuleName() === "MASTERREPORT"
                    ? "Master Report"
                    : ComponentTypeDisplayMapping[
                        getCurrentModuleName() as ComponentAnalysisType
                      ]}
                </div>
                {
                  <>
                    <DisclosureNavigationToggle
                      selected={selectedNav}
                      setSelected={setSelectedNav}
                    />
                    {selectedNav === DisclosureNavigationSection.ANALYSIS ? (
                      <AnalysesNavViewer
                        disclosureID={disclosurePackage.id}
                        masterReport={disclosurePackage.analysis.master}
                        components={Object.values(
                          disclosurePackage.analysis.components
                        )}
                        customAnalyses={Object.values(
                          disclosurePackage.analysis.custom_analyses
                        )}
                        currentAnalysisModule={currentAnalysisModule}
                        setCurrentAnalysisModule={setCurrentAnalysisModule}
                      />
                    ) : (
                      <DisclosureNavigationFiles pdfs={pdfFiles} />
                    )}
                  </>
                }
              </div>
              <AnalysisModuleViewerWrapper
                disclosurePackage={disclosurePackage}
                currentAnalysisModule={currentAnalysisModule}
                pdfCache={pdfCache}
                setSelectedPage={setSelectedPage}
              />
            </div>
            <PDFViewer pdfs={pdfFilesToRender} selected={selectedPage} />
          </div>
        </>
      ) : (
        <DisclosureAnalysisShimmy />
      )}
      <h3 className="disclosure-viewer-footer">
        Due to the sensitive nature of disclosure documents in a real estate
        transaction, we advise you to conduct further due diligence and we do
        not accept responsibility for any oversight.
      </h3>
    </div>
  );
}

export default DisclosureAnalysisViewer;

function AnalysisModuleViewerWrapper({
  disclosurePackage,
  currentAnalysisModule,
  pdfCache,
  setSelectedPage,
}: {
  disclosurePackage: DisclosurePackage;
  currentAnalysisModule: AnalysisModule | null;
  pdfCache: PDFCache;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const selectedComponent =
    currentAnalysisModule === null
      ? disclosurePackage.analysis.master
      : currentAnalysisModule;

  return (
    <div className="disclosure-viewer-component-analysis">
      <AnalysisModuleViewer
        disclosurePackage={disclosurePackage}
        analysisModule={selectedComponent}
        pdfCache={pdfCache}
        setSelectedPage={setSelectedPage}
      />
    </div>
  );
}
