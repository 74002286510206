/* React */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* UI Components */
import Navbar from "../../Common/Navbar";
import PhoneInput from "react-phone-number-input";

/* Functions and Utils */
import { parsePhoneNumberFromString } from "libphonenumber-js";

/* Styling */
import ErrorCircle from "../../../assets/error_circle.svg";
import "./ForgotUsername.css";
import { notSignedInRequirement, withRequirements } from "../../Requirements";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function ForgotUsername() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [disableSubmission, setDisableSubmission] = useState<boolean>(true);
  const navigate = useNavigate();

  const validatePhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    if (!phone || !phone.isValid()) {
      return "Invalid phone number format";
    }
    setDisableSubmission(false);
    return "";
  };

  const validateForm = (value: string) => {
    let error = "";

    if (!disableSubmission) {
      setErrorMessage(error);
      return;
    }
    error = validatePhoneNumber(value);

    setErrorMessage(error);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const uploadData = new FormData();
    uploadData.append("phone", phoneNumber);

    const requestOptions = {
      method: "POST",
      body: uploadData,
    };

    try {
      const response = await fetch(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/password-reset-request`,
        requestOptions
      );

      if (response.ok) {
        navigate("/verify-code");
      } else if (response.status === 404) {
        setErrorMessage(
          "No verified users associated with the provided phone number."
        );
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Server error. Please try again later.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="forgot-username">
        <h1 className="forgot-username__title">Forgot Username</h1>

        <form className="forgot-username__form" onSubmit={handleSubmit}>
          <p className="forgot-username__message">Enter your phone number</p>
          <PhoneInput
            international
            value={phoneNumber}
            defaultCountry="US"
            onChange={(value: string | undefined) => {
              setPhoneNumber(value || "");
              validateForm(value || "");
            }}
            required
            className="forgot-username__input"
            placeholder="Phone Number"
          />

          <div className="forgot-username__error-message">
            {errorMessage && (
              <p className="forgot-username__password-alert">
                <img
                  src={ErrorCircle}
                  alt="Error Icon"
                  className="forgot-username__error-icon"
                />
                {errorMessage}
              </p>
            )}
          </div>

          <button
            type="submit"
            className={`forgot-username__button forgot-username__button--primary ${
              disableSubmission ? "forgot-username__button--disabled" : ""
            }`}
            disabled={disableSubmission}
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
}

export default withRequirements(ForgotUsername, [notSignedInRequirement]);
