/* React */
import { useEffect, useState } from "react";

/* Data Models */
import ComponentAnalysisType, {
  MasterReportSectionCategories,
  ComponentCategoryDisplayMapping,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import {
  MasterReportBullet,
  MasterReportModule,
  MasterReportSection,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/MasterReportModule";

import { SelectedPage } from "../../PDFViewer/PDFViewer";

/* Functions and Utils */
import { isConditionValue } from "../../../../../../model/DisclosureAnalysis/Elements";
import { isRatingValue } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/HOAFinancials/HOAFinancials";

/* Styling */
import "./MasterReport.css";
import "../ComponentViewer/ComponentViewers/Components.css";
import arrowIcon from "../assets/SectionArrow.svg";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* COMPONENT PROPS */
export interface MasterReportModuleViewerProps {
  masterAnalysis: MasterReportModule;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}

/* COMPONENT */
export default function MasterReportModuleViewer({
  masterAnalysis,
  setSelectedPage,
}: MasterReportModuleViewerProps): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const categorizedMasterReportSections = Object.fromEntries(
    Object.keys(MasterReportSectionCategories).map((category) => [
      category,
      Object.values(masterAnalysis.sections).filter((section) =>
        MasterReportSectionCategories[category].includes(section.section_name)
      ),
    ])
  );

  useEffect(() => {
    if (masterAnalysis) {
      const initialExpandedSections = Object.values(masterAnalysis).reduce(
        (acc, key) => {
          acc[key.type] = true;
          return acc;
        },
        {} as { [key: string]: boolean }
      );
      setExpandedSections(initialExpandedSections);
    }
  }, [masterAnalysis]);

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  function MasterSectionBrief({
    section,
  }: {
    section: MasterReportSection;
  }): JSX.Element {
    if (section.brief === null) {
      return (
        <span className="component-analysis-section-header-brief">
          {section.bullets.length}
        </span>
      );
    }
    if (
      isRatingValue(section.brief) &&
      section.section_name === ComponentAnalysisType.HOAFINANCIALS
    ) {
      return (
        <span className="component-analysis-section-header-brief">
          <span>
            <span className={`hoa-financials-rating ${section.brief}`}>
              {section.brief}
            </span>
          </span>
        </span>
      );
    }
    if (isConditionValue(section.brief)) {
      return (
        <span className="component-analysis-section-header-brief">
          <span>
            <span className={`condition ${section.brief}`}>
              {section.brief}
            </span>
          </span>
        </span>
      );
    }
    return (
      <span className="component-analysis-section-header-brief">
        <span>{section.brief}</span>
      </span>
    );
  }

  return (
    <div className="analysis-module">
      <div className="component-wrapper">
        <div className="master-report-title">Master Report</div>
        <br />
        {!masterAnalysis ||
        Object.entries(masterAnalysis.sections).length === 0 ? (
          <div>
            Wait for a file analysis to complete to see a master report.
          </div>
        ) : (
          <div>
            {Object.entries(categorizedMasterReportSections).map(
              ([categoryName, categoryComponents], index) =>
                categoryComponents.length > 0 && (
                  <div key={index} className="master-report-category">
                    <div className="master-report-category-name">
                      {ComponentCategoryDisplayMapping[categoryName]}
                    </div>
                    <div>
                      {categoryComponents.map((section, sectionIndex) => (
                        <div
                          key={sectionIndex}
                          className="component-analysis-section"
                        >
                          <div
                            className="component-analysis-section-header"
                            onClick={() => toggleSection(section.section_name)}
                          >
                            <div>
                              <img
                                src={arrowIcon}
                                alt="toggle"
                                className={`component-analysis-section-header-arrow ${
                                  expandedSections[section.section_name]
                                    ? "expanded"
                                    : "collapsed"
                                }`}
                              />
                              {section.section_name}
                            </div>
                            <MasterSectionBrief section={section} />
                          </div>
                          {expandedSections[section.section_name] && (
                            <div className="component-analysis-section-list">
                              <BulletListComponent
                                bullets={section.bullets}
                                setSelectedPage={setSelectedPage}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

interface BulletListProps {
  bullets: MasterReportBullet[];
  indentLevel?: number; // Optional prop to track indentation level
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}

const BulletListComponent: React.FC<BulletListProps> = ({
  bullets,
  indentLevel = 0,
  setSelectedPage,
}) => {
  const indentationStyle = {
    marginLeft: `${indentLevel * 25}px`, // Indentation based on indentLevel
  };

  return (
    <div style={indentationStyle}>
      {bullets.map((bullet, index) => (
        <div key={index}>
          <div
            className={`master-report-section-item ${
              indentLevel >= 1 ? "indented" : ""
            } ${bullet.reference ? "has-reference" : ""}`}
            onClick={() => {
              if (bullet.reference) {
                setSelectedPage({
                  fileID: bullet.reference.pdf?.file_id,
                  page: bullet.reference.page,
                  region: bullet.reference.region,
                } as SelectedPage);
              }
            }}
          >
            <span className="master-report-section-item-description">
              {bullet.content}
            </span>
          </div>
          {bullet.sub_bullets.length > 0 && (
            <BulletListComponent
              bullets={bullet.sub_bullets}
              indentLevel={indentLevel + 1}
              setSelectedPage={setSelectedPage}
            />
          )}
        </div>
      ))}
    </div>
  );
};
