import React, { useState } from "react";
import arrowIcon from "../../../assets/SectionArrow.svg";
import "./RoofInspection.css";
import {
  RoofInspectionAnalysisItem,
  RoofInspectionAnalysis,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/RoofInspection/RoofInspection";
import {
  AnalysisAttributeCategory,
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

export default function RoofInspectionAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: RoofInspectionAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({ "Red Flags": true, "Pertinent Info": false, Other: false });

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  const categorizedItems = analysis.items.reduce(
    (
      acc: {
        redFlags: RoofInspectionAnalysisItem[];
        info: RoofInspectionAnalysisItem[];
        other: RoofInspectionAnalysisItem[];
      },
      item: RoofInspectionAnalysisItem
    ) => {
      if (item.category === AnalysisAttributeCategory.REDFLAG)
        acc.redFlags.push(item);
      else if (item.category === AnalysisAttributeCategory.INFO)
        acc.info.push(item);
      else acc.other.push(item);
      return acc;
    },
    { redFlags: [], info: [], other: [] }
  );

  const renderItems = (items: RoofInspectionAnalysisItem[]) => (
    <div className="component-analysis-section-list">
      {items
        .sort(
          (a, b) =>
            SeverityLevelOrder[a.severity] - SeverityLevelOrder[b.severity]
        )
        .map((item, index) => (
          <div
            key={index}
            className={`component-analysis-section-item ${
              item.reference ? "has-reference" : ""
            }`}
            onClick={() => {
              if (item.reference) {
                setSelectedPage({
                  fileID: item.reference.pdf?.file_id,
                  page: item.reference.page,
                  region: item.reference.region,
                } as SelectedPage);
              }
            }}
          >
            <div className="component-analysis-section-item-description bold">
              {item.description}
            </div>
            <div>
              {item.severity !== SeverityLevel.NOTAPPLICABLE && (
                <div className={`severity-box ${item.severity}`}>
                  {item.severity}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );

  const renderSection = (
    items: RoofInspectionAnalysisItem[],
    title: string
  ) => {
    if (items.length === 0) {
      return null;
    }
    return (
      <div className="component-analysis-section">
        <div
          className="component-analysis-section-header"
          onClick={() => toggleSection(title)}
        >
          <div>
            <img
              src={arrowIcon}
              alt="toggle"
              className={`component-analysis-section-header-arrow ${
                expandedSections[title] ? "expanded" : "collapsed"
              }`}
            />
            {title}
          </div>
          <div className="component-analysis-section-header-brief">
            {items.length}
          </div>
        </div>
        {expandedSections[title] && renderItems(items)}
      </div>
    );
  };

  return (
    <div>
      <div className="component-analysis-top-level-info">
        <table>
          <tbody>
            <tr>
              <td
                className="component-analysis-top-level-info-item"
                style={{ color: analysis.overall_condition, minWidth: "215px" }}
              >
                Overall Condition:&nbsp;&nbsp;
              </td>
              <td
                className="component-analysis-top-level-info-item"
                style={{ color: analysis.overall_condition, minWidth: "215px" }}
              >
                <span className={`condition ${analysis.overall_condition}`}>
                  {analysis.overall_condition}
                </span>
              </td>
            </tr>
            <tr>
              <td className="component-analysis-top-level-info-item">
                Estimated Repair Cost:&nbsp;&nbsp;
              </td>
              <td className="component-analysis-top-level-info-item">
                <span style={{ color: "#c20202" }}>
                  {analysis.estimated_repair_cost}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {renderSection(categorizedItems.redFlags, "Red Flags")}
      {renderSection(categorizedItems.info, "Pertinent Info")}
      {renderSection(categorizedItems.other, "Other")}
    </div>
  );
}
