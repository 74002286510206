import { Routes, Route, Navigate } from "react-router-dom";
import Scroll from "./Scroll";

import { PrivateAnalysis, PublicAnalysis } from "../Pages/DisclosureAnalysis";
import DisclosureUpload from "../Pages/DisclosureUpload";
import FAQ from "../Pages/FAQ/FAQ";
import LogIn from "../Pages/LogIn";
import Paywall from "../Pages/Paywall";
import Signup from "../Pages/Signup";
import NotFound from "../Pages/NotFound";
import VerifyPhone from "../Pages/VerifyPhoneVerificationCode/VerifyPhoneVerificationCode";
import ForgotUsername from "../Pages/ForgotUsername";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { Landing } from "../Pages/Landing/Landing";
import TermsOfService from "../Pages/Legal/TermsOfServices";
import PrivacyPolicy from "../Pages/Legal/PrivacyPolicy";
import SendPhoneVerificationCode from "../Pages/SendPhoneVerificationCode/SendPhoneVerificationCode";

function App() {
  return (
    <div>
      <Scroll />
      <Routes>
        {/* General */}
        <Route path="/" element={<Landing />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Disclosure Analysis */}
        <Route path="/disclosure" element={<DisclosureUpload />} />
        <Route path="/disclosure/analysis" element={<PrivateAnalysis />} />
        <Route
          path="/disclosure/analysis/shared"
          element={<PublicAnalysis />}
        />

        {/* Account */}
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/send-phone-verification-code"
          element={<SendPhoneVerificationCode />}
        />
        <Route path="/verify-phone" element={<VerifyPhone />} />
        <Route path="/forgot-username" element={<ForgotUsername />} />

        {/* Subscriptions / Payments */}
        <Route path="/purchase" element={<Paywall />} />
        <Route path="/return" element={<Navigate to="/404" replace />} />

        {/* Void */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </div>
  );
}

export default App;
