/* React */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/* UI Components */
import Navbar from "../../Common/Navbar";
import DisclosureAnalysisViewer from "./DisclosureViewer/DisclosureViewer";

/* Data Models */
import { DisclosurePackage } from "../../../model/DisclosureAnalysis/DisclosurePackage";
import { PDFFile } from "../../../model/DisclosureAnalysis/PDFFile";
import { PDFReferenceFirestore } from "../../../model/DisclosureAnalysis/PDFReferenceFirestore";

/* Functions and Utils */
import { openDB } from "idb";
import {
  getDisclosureWebSocketForCurrentUser,
  fetchPdfFile,
  getDisclosureWebSocketFromLink,
  getDisclosureWebSocket,
} from "./api/";
import PDFCache from "../../../utils/cache/pdf";
import {
  withRequirements,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  userSubscribedOrTrialedRequirement,
} from "../../Requirements";

/* Styling */
import "./DisclosureAnalysis.css";

function DisclosureAnalysis(): JSX.Element {
  /**
   * Constants
   */
  const disclosureId = new URLSearchParams(useLocation().search).get(
    "disclosure"
  );
  let uid = new URLSearchParams(useLocation().search).get("uid");
  const link = new URLSearchParams(useLocation().search).get("link");
  const navigate = useNavigate();
  const cacheRef = React.useRef<PDFCache | null>(null);

  /**
   * State Variables
   */
  const [disclosurePackage, setDisclosurePackage] =
    useState<DisclosurePackage | null>(null);
  const [pdfReferences, setPdfReferences] = useState<PDFReferenceFirestore[]>(
    []
  );
  const [pdfFiles, setPdfFiles] = useState<PDFFile[]>([]);
  const [pdfCache, setPdfCache] = useState<PDFCache | null>(null);

  /**
   * API Calls
   */
  const getPDFs = async (pdfs: PDFReferenceFirestore[]) => {
    if (pdfCache) {
      const PDFsArray = await Promise.all(
        pdfs.map(async (pdf) => {
          const cachedPDF = await pdfCache.get(pdf.file_id);
          if (cachedPDF) {
            return cachedPDF;
          } else {
            const fetchedPDF = await fetchPdfFile(pdf);
            await pdfCache.store(fetchedPDF, pdf.file_id);
            return fetchedPDF;
          }
        })
      );

      setPdfFiles(PDFsArray);
    } else {
      console.warn("No PDF Cache setup");
    }
  };

  async function disclosureWebsocket(
    uid: string | null,
    disclosureId: string | null,
    link: string | null
  ) {
    let ws: WebSocket;
    // if UID is not provided, we assume the current user
    if (link) {
      ws = await getDisclosureWebSocketFromLink(link);
    } else if (uid) {
      if (!disclosureId) {
        navigate("/404");
        return;
      }
      ws = await getDisclosureWebSocket(uid, disclosureId, null);
    } else {
      ws = await getDisclosureWebSocketForCurrentUser(disclosureId);
    }
    if (!ws) {
      navigate("/404");
      return;
    }

    // Disclosure Package comoponents
    let pdfReferencesWS: PDFReferenceFirestore[] = [];

    ws.onopen = () => {};

    ws.onmessage = async (event) => {
      const disclosurePackageResponse: DisclosurePackage = JSON.parse(
        JSON.parse(event.data)
      );
      setDisclosurePackage(disclosurePackageResponse);

      // Adjust PDFReferences current
      if (
        JSON.stringify(disclosurePackageResponse.files) !==
        JSON.stringify(pdfReferencesWS)
      ) {
        pdfReferencesWS = disclosurePackageResponse.files;
        setPdfReferences(disclosurePackageResponse.files);
      }
    };

    ws.onerror = (error) => {
      console.error(error);
    };

    ws.onclose = () => {};

    return () => {
      ws.close();
    };
  }

  /**
   * Hooks
   */
  useEffect(() => {
    async function initializePDFCache() {
      const db = await openDB("DisclosureAnalysis", 1, {
        upgrade(db) {
          db.createObjectStore("pdfs");
        },
      });
      setPdfCache(new PDFCache(db));
    }

    initializePDFCache();
    disclosureWebsocket(uid, disclosureId, link);

    return () => {
      // Close PDFCache
      if (cacheRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cacheRef.current.close("DisclosureAnalysis");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pdfReferences.length > 0) {
      getPDFs(pdfReferences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfReferences]);

  /**
   * Rendering
   */
  if (pdfCache === null) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Navbar />
      <DisclosureAnalysisViewer
        disclosurePackage={disclosurePackage}
        pdfFiles={pdfFiles}
        pdfCache={pdfCache}
        disclosureId={disclosureId}
        uid={uid}
        link={link}
      />
    </div>
  );
}

function PrivateAnalysis(): JSX.Element {
  return React.createElement(
    withRequirements(DisclosureAnalysis, [
      userSignedInRequirement,
      userPhoneVerifiedRequirement,
      userSubscribedOrTrialedRequirement,
    ])
  );
}

function PublicAnalysis(): JSX.Element {
  return React.createElement(DisclosureAnalysis);
}

export { PrivateAnalysis, PublicAnalysis };
