/* React */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/* Data Models */
import PhoneInput from "react-phone-number-input";

/* Utility Functions */
import { postWithAuth } from "../../../firebase/authentication/auth";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { fetchLoginToken } from "../LogIn/LogIn";

/* Styling */
import ErrorCircle from "../../../assets/error_circle.svg";
import "react-phone-number-input/style.css";
import "./Signup.css";
import Navbar from "../../Common/Navbar";
import { notSignedInRequirement, withRequirements } from "../../Requirements";
import Spinner from "../../Common/Spinner/FormSpinner";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function Signup() {
  /* 
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    fullName: "",
    phoneNumber: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const navigate = useNavigate();

  /* 
  ================================
  UI Functionality
  ================================
  */
  const validatePhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    if (!phone || !phone.isValid()) {
      return "Invalid phone number format";
    }
    return "";
  };
  const validateForm = (name: string, value: string) => {
    let error = "";

    if (!disableSubmission) {
      setErrorMessage(error);
      return;
    }

    if (name === "email" && !/\S+@\S+\.\S+/.test(value) && value.length > 0) {
      error = "Invalid email format.";
    } else if (name === "password" && value.length < 6 && value.length > 0) {
      error = "Password must be at least 6 characters.";
    } else if (
      (name === "password" && value !== formData.confirmPassword) ||
      (name === "confirmPassword" && value !== formData.password)
    ) {
      error = "Passwords do not match.";
    } else if (name === "phoneNumber") {
      error = validatePhoneNumber(value);
    }

    setErrorMessage(error);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setErrorMessage("");
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value);
  };

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  /* 
  ================================
  SUBMISSION
  ================================
  */
  const disableSubmission =
    !formData.email ||
    !/\S+@\S+\.\S+/.test(formData.email) ||
    formData.email.endsWith("@") ||
    formData.password.length < 6 ||
    formData.password !== formData.confirmPassword ||
    !termsAccepted;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const uploadData = new FormData();
    uploadData.append("email", formData.email);
    uploadData.append("username", formData.username);
    uploadData.append("password", formData.password);
    uploadData.append("name", formData.fullName);
    uploadData.append("phone", formData.phoneNumber);

    const requestOptions = {
      method: "POST",
      body: uploadData,
    };

    try {
      const response = await fetch(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/signup`,
        requestOptions
      );

      if (response.ok) {
        await fetchLoginToken(formData.username, formData.password); // signin
        try {
          const response = await postWithAuth(
            `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone/send-verification-code`
          );

          if (response.ok) {
            navigate(
              `/verify-phone?number=${encodeURIComponent(formData.phoneNumber)}`
            );
          } else {
            console.error(
              "Failed to send verification code:",
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error sending verification code:", error);
        }
      } else {
        const errorData = await response.json();
        switch (response.status) {
          case 409:
            if (errorData.detail.trim().toLowerCase().includes("username")) {
              setErrorMessage("Username already in use.");
            } else if (
              errorData.detail.trim().toLowerCase().includes("phone")
            ) {
              setErrorMessage(
                "Phone number is already verified with another user."
              );
            }
            break;
          case 500:
            setErrorMessage("Server error. Please try again later.");
            break;
          default:
            setErrorMessage("An unexpected error occurred. Please try again.");
        }
      }
    } catch (error) {
      setErrorMessage("Network error. Please check your connection.");
      console.error("Error during signup: ", error);
    }
    setIsLoading(false);
  };

  /* 
  ================================
  RENDERING
  ================================
  */

  return (
    <div>
      <Navbar />
      <div className="signup">
        <div className="signup__title">Register</div>

        <form className="signup__form">
          <div className="signup__inputs">
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              className="signup__input"
              placeholder="Full Name"
            />

            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="signup__input"
              placeholder="Email"
            />

            <PhoneInput
              international
              value={formData.phoneNumber}
              defaultCountry="US"
              onChange={(value: string | undefined) => {
                setFormData((prevState) => ({
                  ...prevState,
                  phoneNumber: value || "",
                }));
                validateForm("phoneNumber", value || "");
              }}
              required
              placeholder="Phone Number"
            />

            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className="signup__input"
              placeholder="Username"
            />

            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="signup__input"
              placeholder="Password"
            />

            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className="signup__input"
              placeholder="Confirm Password"
            />
          </div>

          <div className="signup__error-message">
            {errorMessage && (
              <p className="signup__password-alert">
                <img
                  src={ErrorCircle}
                  alt="Error Icon"
                  className="signup__error-icon"
                />
                {errorMessage}
              </p>
            )}
          </div>

          <div className="signup__info">
            <div className="signup__terms-section">
              <input
                className="signup__agreement-checkbox"
                type="checkbox"
                checked={termsAccepted}
                onChange={handleTermsChange}
              />
              <p className="signup__agreement-text">
                I have read the <a href="/terms-of-service">terms of service</a>
              </p>
            </div>
          </div>

          <div className="signup__controls">
            {isLoading ? (
              <Spinner />
            ) : (
              <button
                onClick={handleSubmit}
                className={`signup__button signup__button--primary ${
                  disableSubmission ? "signup__button--disabled" : ""
                }`}
                disabled={disableSubmission}
              >
                Continue
              </button>
            )}
          </div>

        </form>

        <div className="signup__link">
          <p className="signup__login-link">
            Already a user? <a href="/login">Login instead.</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default withRequirements(Signup, [notSignedInRequirement]);
